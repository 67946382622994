
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import { useI18n } from "vue-i18n/index";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { ElMessage } from "element-plus";
import { func } from "@/core/plugins/globalfunc";

declare const window: any;
export default defineComponent({
  name: "customers-listing",
  mixins: [func],
  components: {
    //Datatable,
  },
  data() {
    return {};
  },
  mounted() {
    this.logout();
  },
  computed: {
    prevRouteValue() {
      return store.state.prevRoute;
    },
  },
  watch: {},
  methods: {
    logout() {
      this.logoutClear();
      ElMessage.warning(this.$t("loggedOut"));
      this.$router.push(this.prevRouteValue.fullPath);
    },
  },
});
